import axios from 'axios';
import { $http } from '@/utils';

const PING_TIMEOUT = 90000;
const MAX_RECONNECT_ATTEMPTS = 5;
const RECONNECT_INTERVAL = 1000;

const WebSocketPlugin = {
	install(Vue) {
		let ws = null;
		let pingTimeout = null;
		let reconnectAttempts = 0;
		let cachedMessages = [];
		let lastMessageTime = Date.now(); // Track the last message timestamp
		let forceClose = false;
		let isConnected = false;

		// Establish a new WebSocket connection
		const connectWebSocket = () => {
			ws = new WebSocket(process.env.VUE_APP_WS_URL);
			console.log('[websocket] Connecting...');

			ws.onmessage = onMessage;
			ws.onerror = onError;
			ws.onclose = onClose;
			ws.onopen = onOpen;
		};

		// Handle WebSocket opening
		const onOpen = () => {
			console.log(`[websocket] Connected to ${process.env.VUE_APP_WS_URL} - ${new Date()}`);
			send({ type: 'init' });

			// Reset the ping timeout when the connection is established
			resetPingTimeout();

			// Reset the reconnect attempts when the connection is established
			reconnectAttempts = 0;
		};

		// Reset the ping timeout
		const resetPingTimeout = () => {
			if (pingTimeout) clearTimeout(pingTimeout);

			// Set a new timeout for sending a ping after the PING_TIMEOUT period
			pingTimeout = setTimeout(() => {
				const currentTime = Date.now();
				if (currentTime - lastMessageTime >= PING_TIMEOUT) {
					send({ route: 'keepalive' });
				}
				resetPingTimeout(); // Set the timeout again for the next ping cycle
			}, PING_TIMEOUT);
		};

		// Close the WebSocket connection
		const closeWebSocket = () => {
			if (ws) ws.close();
		};

		// Handle incoming WebSocket messages
		const onMessage = async(event) => {
			try {
				const message = JSON.parse(event.data);

				if (message.status === 200) {
					if (!isConnected) isConnected = true;

					if (message.type === 'init' || message.route === 'keepalive') {
						cachedMessages.forEach((msg) => send(msg));
					}
					cachedMessages = [];
				} else if (message.status === 401) {
					await refreshToken();
					send({ route: 'keepalive' });
				} else if (message?.reconnect || message.status === 410) {
					reconnect();
				} else {
					console.error(`[websocket] Error: ${event.data}`);
					Vue.prototype.$websocket.$emit('error', message);
				}

				Vue.prototype.$websocket.$emit('message', message);
			} catch (e) {
				console.error('Failed to parse message data as JSON:', event.data);
				Vue.prototype.$websocket.$emit('error', e);
			}
		};

		// Handle WebSocket errors
		const onError = (error) => {
			console.error('WebSocket error:', error);
			Vue.prototype.$websocket.$emit('error', error);
		};

		// Handle WebSocket closure
		const onClose = (event) => {
			console.warn(`WebSocket connection closed: - ${new Date()}`, event);
			Vue.prototype.$websocket.$emit('close', event);
			if (pingTimeout) clearTimeout(pingTimeout);

			if (!forceClose) reconnect();
		};

		// Attempt to reconnect the WebSocket
		const reconnect = () => {
			if (reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
				const timeout = RECONNECT_INTERVAL * Math.pow(2, reconnectAttempts);

				Vue.prototype.$websocket.$emit('reconnect', { timeout: timeout / 1000, attempt: reconnectAttempts + 1, maxAttempts: MAX_RECONNECT_ATTEMPTS, type: 'disconnect' });

				setTimeout(() => {
					reconnectAttempts++;
					connectWebSocket();
				}, timeout);
			} else {
				console.warn(`WebSocket failed to reconnect after ${MAX_RECONNECT_ATTEMPTS} attempts.`);
			}
		};

		// Send a message through the WebSocket
		const send = (message, cache = true) => {
			lastMessageTime = Date.now(); // Update the last message time when sending a message

			if (message.route !== 'keepalive' && message.type !== 'init' && !message.cache && cache) cachedMessages.push({ ...message, cache: true });

			if (!ws || ws.readyState !== WebSocket.OPEN) {
				console.log(`[websocket] Error: Connection not open`);
				Vue.prototype.$websocket.$emit('error', { message: 'Connection not open' });
				return;
			}

			delete message.cache;
			message.authorization = window.localStorage.getItem('air.authorization');
			ws.send(JSON.stringify(message));
			console.log(`[websocket] Message sent: ${JSON.stringify(message)}`);

			// Reset the ping timeout whenever a message is sent
			resetPingTimeout();
		};

		// Refresh the access token
		const refreshToken = () => {
			return axios
				.post(`${process.env.VUE_APP_API_URL}/account/refresh`, {
					token: window.localStorage.getItem('air.refreshToken')
				})
				.then(({ data }) => {
					$http.setToken(data.accessToken, data.refreshToken);
				})
				.catch((e) => {
					if (e.response?.status === 403 || e.response?.config?.url.includes('account/refresh')) {
						$http.forceLogin();
					}
					return Promise.reject(e.response || e);
				});
		};

		const retry = () => {
			reconnectAttempts = 0;

			if (!ws || ws.readyState !== WebSocket.OPEN) {
				connectWebSocket();
			} else if (cachedMessages.length > 0) {
				// Attempt to resend each cached message with retry logic
				cachedMessages.forEach((msg) => sendWithRetry(msg));
			} else {
				// Send a keepalive message with retry logic
				sendWithRetry({ route: 'keepalive' });
			}
		};

		// Generalized sendWithRetry function
		const sendWithRetry = (message) => {
			send(message, false);

			Vue.prototype.$websocket.$once('message', (response) => {
				if (response.status !== 200 && reconnectAttempts < MAX_RECONNECT_ATTEMPTS) {
					const timeout = RECONNECT_INTERVAL * Math.pow(2, reconnectAttempts); // Exponential backoff
					Vue.prototype.$websocket.$emit('reconnect', {
						timeout: timeout / 1000,
						attempt: reconnectAttempts + 1,
						maxAttempts: MAX_RECONNECT_ATTEMPTS,
						type: 'error'
					});

					setTimeout(() => {
						reconnectAttempts++;
						sendWithRetry(message); // Retry sending the message
					}, timeout);
				} else if (response.status === 200) {
					// Message was successfully sent and acknowledged
					console.log(`[websocket] Message sent successfully: ${JSON.stringify(message)}`);
				} else {
					// Exceeded retry attempts, log or handle the failure case
					console.error(`[websocket] Failed to send message after retries: ${JSON.stringify(message)}`);
				}
			});
		};

		const forceCloseWebSocket = () => {
			forceClose = true;
			closeWebSocket();
		};

		window.addEventListener('beforeunload', () => closeWebSocket());

		Vue.prototype.$sendMessage = send;
		Vue.prototype.$connectWebSocket = connectWebSocket;
		Vue.prototype.$closeWebSocket = closeWebSocket;
		Vue.prototype.$forceClose = forceCloseWebSocket;
		Vue.prototype.$retry = retry;
		Vue.prototype.$isConnected = () => isConnected;
		Vue.prototype.$websocket = new Vue();
	}
};

export default WebSocketPlugin;
