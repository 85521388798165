import router from '@/router';
import axios from 'axios';
import { EventBus } from '@/utils';

/**
 * @namespace Utils
 * @method $http
 * @exports Utils/$http
 * @description Util giving http access using axios
 * @date 2020/02/21
 * @license no license
 * @copywrite Answers In Retirement Limited
 */

const config = {
	baseURL: process.env.VUE_APP_API_URL,
	withCredentials: false,
	headers: { Accept: 'application/json', 'X-Requested-With': 'XMLHttpRequest', 'Content-Type': 'application/json' }
};

const $http = axios.create(config);

const tabID = window.sessionStorage.getItem('air.tabID') || Math.random();
window.sessionStorage.setItem('air.tabID', tabID);
window.addEventListener('storage', (event) => {
	if (event.key === 'air.authorization') {
		if (tabID !== window.localStorage.getItem('air.authorizationTabID')) location.reload();
	}
});

$http.setToken = (accessToken, refreshToken) => {
	window.localStorage.setItem('air.authorizationTabID', tabID);
	window.localStorage.setItem('air.authorization', accessToken);
	window.localStorage.setItem('air.refreshToken', refreshToken);
};

$http.clearToken = () => {
	window.localStorage.removeItem('air.authorization');
	window.localStorage.removeItem('air.refreshToken');
};

$http.forceLogin = () => {
	$http.clearToken();
	const redirectUrl = router.history?.pending?.fullPath;
	window.location.href = `/advisers${redirectUrl ? `?redirect=${redirectUrl}` : ''}`;
};

const versionUpdateRequested = (response) => {
	const version = response?.headers?.['airsourcing-webappversion'];
	if (!versionUpdateAvailable && version && version !== process.env.VUE_APP_VERSION) {
		EventBus.$emit('app-version-update', version);
		versionUpdateAvailable = true;
	}

	return versionUpdateAvailable;
};

// Request Interceptors
const authInterceptor = (request) => {
	let token = window.localStorage.getItem('air.authorization');
	if (token && !request.headers?.IgnoreAuthorization) request.headers['Authorization'] = 'Bearer ' + token;
	delete request.headers.IgnoreAuthorization;

	return request;
};

const cacheInterceptor = (request) => {
	if (process.env.VUE_APP_CACHE_URL && request.cache) {
		request.apiPath = request.url;
		request.url = `${process.env.VUE_APP_CACHE_URL}/${request.cachePath}`;
		request.apiMethod = request.method;
		request.method = 'get';
	}

	return request;
};

$http.interceptors.request.use(authInterceptor);
$http.interceptors.request.use(cacheInterceptor);

// Response Interceptors
let pendingRefresh = false;
let pendingRequests = [];
let versionUpdateAvailable = false;

$http.interceptors.response.use(
	(response) => {
		if (versionUpdateRequested(response)) return Promise.reject(response);

		// auto cache auth token to local storage
		if (response.config.url.includes('account/authenticate') && response.data.accessToken) $http.setToken(response.data.accessToken, response.data.refreshToken);

		return response;
	},
	(error) => {
		if (versionUpdateRequested(error.response)) return Promise.reject(error.response);

		if (error.config.cache && error.config.url === `${process.env.VUE_APP_CACHE_URL}/${error.config.cachePath}`) {
			error.config.cache = false;
			error.config.url = error.config.apiPath;
			error.config.method = error.config.apiMethod;

			return $http(error.config);
		}

		if (error.response?.status === 401) {
			// if refresh token request is pending, add request to queue
			if (pendingRefresh) {
				let resolve;
				let reject;

				// need to create and return a promise to keep the chain going
				const promise = new Promise((res, rej) => {
					resolve = res;
					reject = rej;
				});

				pendingRequests.push({ config: error.config, resolve, reject });

				return promise;
			}

			pendingRefresh = true;

			return axios
				.post(`${config.baseURL}/account/refresh`, {
					token: window.localStorage.getItem('air.refreshToken')
				})
				.then((response) => {
					// update the token on successful refresh
					$http.setToken(response.data.accessToken, response.data.refreshToken);

					// send all requests waiting on queue after the token has been updated
					(pendingRequests || []).forEach((request) =>
						$http(request.config)
							.then((response) => request.resolve(response))
							.catch((error) => request.reject(error))
					);

					// send the original request with a valid token
					let originalRequest = error.config;
					originalRequest.headers['Authorization'] = 'Bearer ' + response.data.accessToken;
					return $http(originalRequest);
				})
				.catch((e) => {
					// Retry failed, clean up and reject the promise
					if (e.response?.status === 403 || e.response?.config?.url.includes('account/refresh')) $http.forceLogin();
					return Promise.reject(e.response || e);
				})
				.finally(() => {
					pendingRefresh = false;
					pendingRequests = [];
				});
		} else if (
			error.response?.status === 403 &&
			!router.history.current.meta.public &&
			(error.response?.config?.url.includes('account/authenticate') || error.response?.data?.message?.includes('Missing Authorization Token'))
		)
			$http.forceLogin();

		return Promise.reject(error.response || error);
	}
);

export default $http;
