<!--
	@name app-enquiry-new-enquiry
	@description Create new enquiry page
	@date 2020/06/26
	@license no license
	@copywrite Answers In Retirement Limited
-->

<template>
	<common-structure-section class="mb-6">
		<template #body>
			<!-- Applicant Details-->
			<common-form-client
				ref="applicantDetails"
				:form="formFromGroup('enquiry', 'client')"
				:clients="clientList"
				:show-error="submitFailed"
				@set-client="setApplicant"
				@remove-client="removeApplicant"
				@set-validation="setValidation"
			/>

			<!-- Property Details -->
			<common-form-property
				ref="propertyDetails"
				:property-form="formFromGroup('enquiry', 'asset')"
				:property="property"
				:client="clientList[0]"
				:show-error="submitFailed"
				@set-property="setProperty"
				@set-validation="setValidation"
			/>

			<!-- Additional Information -->
			<common-form-section
				ref="additionalInformation"
				title="Additional Information"
				:form="formFromGroup('enquiry', 'additional_information')"
				:item="additionalInformation"
				@set-validation="setValidation"
			/>

			<!-- Attachments -->
			<common-form-section
				ref="attachments"
				title="Attachments"
				:form="formFromGroup('enquiry', 'attachments')"
				:item="attachments"
				@file-upload-in-progress="fileUploadProgressHandler"
			/>

			<div class="text-center">
				<v-alert v-show="!isValid" transition="slide-y-transition" dense prominent outlined text border="left" type="error" class="mb-6 text-body-1 text-left">
					You cannot proceed until ALL sections are validated. Please go back and address any sections displaying this error message.
				</v-alert>
				<v-btn v-show="form('enquiry')" large color="primary" :loading="isSubmitting" :disabled="submitDisabled" @click="submitEnquiryHandler">
					Submit Enquiry
				</v-btn>
			</div>
		</template>
	</common-structure-section>
</template>

<script>
	import { mapActions, mapGetters } from 'vuex';
	import { ElementTools } from '@/utils';
	import { isEmpty } from 'lodash';
	import CommonStructureSection from '@/component/common/structure/section';
	import CommonFormClient from '@/component/common/form/client';
	import CommonFormProperty from '@/component/common/form/property';
	import CommonFormSection from '@/component/common/form/section';

	export default {
		name: 'app-enquiry-new-enquiry',

		components: {
			CommonStructureSection,
			CommonFormClient,
			CommonFormProperty,
			CommonFormSection
		},

		data() {
			return {
				selectedClient: {},
				clientList: [{}],
				property: {},
				additionalInformation: {},
				attachments: {},
				validations: {
					applicant: false,
					property: false,
					additionalInformation: false
				},
				submitFailed: false,
				submitDisabled: false,
				isSubmitting: false
			};
		},

		computed: {
			...mapGetters('CmsForm', ['form', 'formFromGroup']),

			isValid() {
				return !Object.keys(this.validations).find((key) => !this.validations[key]);
			},

			submitEnquiryPayload() {
				// Client
				const client = this.clientList.map((item) => {
					const [day, month, year] = item.dateOfBirth.split('/');
					const dateOfBirth = this.$moment.utc(`${month}/${day}/${year}`).toISOString();
					return { ...item, dateOfBirth };
				});

				// Asset
				const property = this.property;
				const asset = {
					type: 'property',
					id: property.id,
					data: {
						detail: {
							ex: { localAuthority: !!(property.additionalCriteria || []).find((item) => item === 'ex_local_authority') },
							buyToLet: !!(property.additionalCriteria || []).find((item) => item === 'a_buy_to_let'),
							ageRestricted: { value: !!(property.additionalCriteria || []).find((item) => item === 'age_restricted') },
							secondHome: !!(property.additionalCriteria || []).find((item) => item === 'second_home'),
							sheltered: !!(property.additionalCriteria || []).find((item) => item === 'sheltered'),
							tenureId: property.tenureId,
							unexpiredLeaseTerm: Number(property.unexpiredLeaseTerm),
							assetDescriptionId: property.assetDescriptionId
						},
						location: {
							address1: property.address1,
							address2: property.address2,
							townCity: property.townCity,
							county: property.county,
							countryId: property.countryId,
							postcode: property.postcode
						},
						mortgage: {
							mortgageTypeId: property.mortgageTypeId,
							outstanding: property.outstandingMortgage
						},
						valuation: { value: parseInt(property.valuation) }
					}
				};

				return {
					client,
					asset,
					process: { ...this.additionalInformation, ...this.attachments },
					matterType: 'enquiry',
					processType: 'response'
				};
			}
		},

		created() {
			this.loadForm('enquiry').then(() => {
				if (this.$route.query.clientId) {
					const clientIds = [this.$route.query.clientId, this.$route.query.associationId].filter((client) => client);
					clientIds.forEach((clientId, index) => this.$refs.applicantDetails?.fetchClient(index, clientId));
				}
				if (this.$route.query.propertyId) this.$refs.propertyDetails?.fetchAsset(this.$route.query.clientId, this.$route.query.propertyId);
			});
		},

		methods: {
			...mapActions('CmsForm', ['loadForm']),
			...mapActions('Enquiry', ['submitEnquiry']),

			/**
			 * @name setApplicant
			 * @description set applicant
			 * @param {Object} payload
			 */
			setApplicant({ dataIndex, data, type }) {
				if (dataIndex === 0 && type !== 'update') {
					this.$refs.propertyDetails?.reset();
					if (!isEmpty(this.property)) this.setProperty({});
				}

				this.$set(this.clientList, dataIndex, data);
				this.$nextTick(() => this.$refs.applicantDetails?.validateForm());
			},

			/**
			 * @name removeApplicant
			 * @description remove applicant
			 * @param {Object} payload
			 */
			removeApplicant(index) {
				this.$delete(this.clientList, index);
				this.$nextTick(() => this.$refs.applicantDetails?.validateForm());
			},

			/**
			 * @name setProperty
			 * @description set property
			 * @param {Object} property
			 */
			setProperty(property) {
				this.property = property;
				this.$nextTick(() => this.$refs.propertyDetails?.validateForm());
			},

			/**
			 * @name setValidation
			 * @description set applicant
			 * @param {Object} validation
			 */
			setValidation(validation) {
				this.validations = { ...this.validations, ...validation };
			},

			/**
			 * @name submitEnquiryHandler
			 * @description Validates form and submits the enquiry to server. Clears the forms afterwards
			 */
			async submitEnquiryHandler() {
				if (!this.isValid) {
					this.submitFailed = true;
					return;
				}

				this.submitFailed = false;
				this.isSubmitting = true;

				this.submitEnquiry(this.submitEnquiryPayload)
					.then((response) => {
						this.$router.push(`/enquiry/${response.data.matterId}`);
						ElementTools.fireNotification(this.$el, 'success', 'Enquiry has been submitted successfully');
					})
					.catch(() => ElementTools.fireNotification(this.$el, 'error', 'An error occured whilst submitting your enquiry. Please try again later'))
					.finally(() => {
						this.isSubmitting = false;
					});
			},

			fileUploadProgressHandler(value) {
				this.submitDisabled = value;
			}
		}
	};
</script>
